<template>
	<button type="button" @click="openSubscribeModal" class="block w-full text-left">
		<div class="rounded-6">
			<div class=" flex flex-wrap items-center space-y-32 tabletWide:space-y-0 tabletWide:space-x-32 tabletWide:space-x-32 tabletWide:flex-nowrap ">
				<figure class="w-full self-stretch tablet:max-w-632 tabletWide:max-w-auto tabletWide:w-4/12 desktop:w-5/12">
					<img :src="$cloudFlare.getImage('/images/GO_Rentals_Newsletter.png')" width="840" height="133" loading="lazy" decoding="async" alt="" class="object-cover rounded-GO w-full h-92 tabletWide:h-full">
				</figure>
				<div class="text-white mr-16 tabletWide:w-5/12">
					<p class="text-24 desktop:text-32 font-bold text-balance">Save 10% on your next adventure</p>
					<p class="text-16 text-pretty">
						Join our GO newsletter for exclusive deals, <br class="desktop:hidden"> travel tips and inspiration!
					</p>
				</div>
				<div class="flex-auto tabletWide:text-center">
					<div class="button button-goWhiteGhost button-large">
						Subscribe
					</div>
				</div>
			</div>
		</div>
	</button>
</template>

<script>
export default {
	methods: {
		async openSubscribeModal() {
			const { default: subscribeModal } = await import("~/components/modals/subscribe.vue")
			this.$modalService.show(subscribeModal)
		},
	},
};
</script>
